import { useCallback, useMemo, useState } from "react";
import styles from "./../FloatingAnnotationToolbar.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import debounce from "debounce";
import classes from "classnames";
import fatIconButtonFactory from "../utils/fatIconButtonFactory";
import {
    addHighlight,
    changeActiveAnnotation,
    deleteAnnotation,
} from "../../../actions/annotations";
import {
    addFloatingPanel,
    removeFloatingPanelById,
} from "../../../actions/sidePanel";
import {
    selectActiveHighlightAnnotation,
    selectAnnotationsOutOfService,
    selectDefaultStyle,
    selectLoggedIn,
} from "../../../selectors";
import { useSelectI18nStringById } from "../../../../util/custom-hooks";
import {
    AddNote,
    AnnotationMark,
    Clear,
    Delete,
    Link,
    Notebook,
    Share,
    Tag,
} from "@churchofjesuschrist/eden-icons";
import DeleteAlert from "../../DeleteAlert";
import Divider from "./Divider";
import { MarkOption } from "./HighlightMenu";
import Copy from "./Copy";
import ForcedSignIn from "../../ForcedSignIn";

const AddMarkButton = fatIconButtonFactory(AnnotationMark);
const CloseButton = fatIconButtonFactory(Clear);
const DeleteButton = fatIconButtonFactory(Delete);
const LinkButton = fatIconButtonFactory(Link);
const NotebookButton = fatIconButtonFactory(Notebook);
const NoteButton = fatIconButtonFactory(AddNote);
const ShareButton = fatIconButtonFactory(Share);
const TagButton = fatIconButtonFactory(Tag);

export const DefaultToolbar = ({
    addFloatingPanel,
    addHighlight,
    annotation,
    annotationsOutOfService,
    changeActiveAnnotation,
    changeView,
    clearSelection,
    defaultStyle,
    deleteAnnotation,
    location,
    loggedIn,
    modalOpen,
    offsets,
    openNotebookModal,
    openTagModal,
    openCrossLinkOverlay,
    range,
    removeFloatingPanelById,
    selectI18nStringById,
}) => {
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);

    const active = annotation?.id || range;
    const expanded = loggedIn && !annotationsOutOfService;
    const { clear, color, underline } =
        annotation?.highlights[0] || defaultStyle || {};

    let style = useMemo(
        () => ({ clear, color, underline }),
        [clear, color, underline]
    );

    const addNewHighlight = useMemo(
        () =>
            debounce(
                async () => {
                    const annotationId = await addHighlight(
                        { offsets, style },
                        location
                    );

                    changeActiveAnnotation(annotationId);

                    return annotationId;
                },
                300,
                true
            ),
        [addHighlight, changeActiveAnnotation, offsets, style, location]
    );

    const handleOpenNotebookModal = async (annotationId) => {
        annotationId = annotationId || (await addNewHighlight());

        openNotebookModal(annotationId);
    };

    const handleOpenTagModal = async (annotationId) => {
        annotationId = annotationId || (await addNewHighlight());

        openTagModal(annotationId);
    };

    const handleOpenCrossLinkOverlay = (annotationId) => {
        openCrossLinkOverlay(annotationId || null);
    };

    const toggleDeleteWarning = () => setDeleteWarningOpen(!deleteWarningOpen);

    const handleCopyClick = () => {
        document.execCommand("copy");
    };

    const handleOpenEditor = useCallback(
        async (annotationId, autoFocus) => {
            annotationId = annotationId || (await addNewHighlight());

            addFloatingPanel({
                annotationId,
                autoFocus,
                id: `${annotationId}-editor`,
                type: "editor",
            });
        },
        [addFloatingPanel, addNewHighlight]
    );

    return (
        <>
            <CloseButton
                onClick={() => {
                    clearSelection();
                    changeView("Closed");
                }}
                title={selectI18nStringById("annotationMenuCloseText")}
                data-testid="fat-close-button"
            />
            <Divider />
            {annotation?.id && !annotationsOutOfService ? (
                <MarkOption
                    className={classes(
                        styles.defaultToolbarMarkOption,
                        !(style.clear || style.underline) &&
                            styles.markOptionHighlight
                    )}
                    clear={style.clear}
                    color={style.color}
                    onClick={() => changeView("HighlightMenu")}
                    title={selectI18nStringById("annotationMenuStyleText")}
                    underline={style.underline}
                    data-testid="fat-mark-button"
                />
            ) : (
                <ForcedSignIn arrowDirection="inlineStart" disabled={!active}>
                    <AddMarkButton
                        data-testid="fat-mark-button"
                        disabled={!active}
                        onClick={() => addNewHighlight()}
                        title={selectI18nStringById("annotationMenuMarkText")}
                    />
                </ForcedSignIn>
            )}
            {expanded && (
                <NoteButton
                    onClick={() => handleOpenEditor(annotation?.id, "notes")}
                    disabled={!active}
                    title={selectI18nStringById("annotationMenuNoteText")}
                    data-testid="fat-note-button"
                />
            )}
            {expanded && (
                <TagButton
                    onClick={() => handleOpenTagModal(annotation?.id)}
                    disabled={!active}
                    title={selectI18nStringById("annotationMenuTagText")}
                    data-testid="fat-tag-button"
                />
            )}
            {expanded && (
                <NotebookButton
                    onClick={() => handleOpenNotebookModal(annotation?.id)}
                    disabled={!active}
                    title={selectI18nStringById("annotationMenuAddToText")}
                    data-testid="fat-notebook-button"
                />
            )}
            {expanded && (
                <LinkButton
                    onClick={() => handleOpenCrossLinkOverlay(annotation?.id)}
                    disabled={!active}
                    title={selectI18nStringById(
                        "annotationMenuOpenCrossLinkOverlayText"
                    )}
                    data-testid="fat-cross-link-button"
                />
            )}
            {expanded && (
                <DeleteButton
                    onClick={toggleDeleteWarning}
                    disabled={!annotation?.id || modalOpen}
                    title={selectI18nStringById("annotationMenuRemoveText")}
                    data-testid="fat-delete-button-test"
                />
            )}
            <Divider />
            <Copy
                handleClick={handleCopyClick}
                disabled={!range}
                title={selectI18nStringById("annotationMenuCopyText")}
            />
            <ShareButton
                onClick={() => changeView("Share")}
                title={selectI18nStringById("annotationMenuShareText")}
                data-testid="fat-share-button"
            />
            {deleteWarningOpen && (
                <DeleteAlert
                    onClose={toggleDeleteWarning}
                    onDelete={() => {
                        removeFloatingPanelById(`${annotation?.id}-editor`);
                        deleteAnnotation(annotation?.id);
                        clearSelection();
                        toggleDeleteWarning();
                    }}
                    message={selectI18nStringById(
                        "deleteAnnotationWarningText"
                    )}
                    title={selectI18nStringById("deleteAnnotationWarningTitle")}
                    testId="main-pane"
                />
            )}
        </>
    );
};

const DefaultToolbarContainer = ({ ...props }) => {
    const location = useLocation();
    const activeAnnotation = useSelector(selectActiveHighlightAnnotation);
    const annotationsOutOfService = useSelector(selectAnnotationsOutOfService);
    const defaultStyle = useSelector(selectDefaultStyle);
    const loggedIn = useSelector(selectLoggedIn);
    const selectI18nStringById = useSelectI18nStringById();

    const dispatch = useDispatch();
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    addHighlight,
                    addFloatingPanel,
                    changeActiveAnnotation,
                    deleteAnnotation,
                    removeFloatingPanelById,
                },
                dispatch
            ),
        [dispatch]
    );

    return (
        <DefaultToolbar
            annotation={activeAnnotation}
            annotationsOutOfService={annotationsOutOfService}
            defaultStyle={defaultStyle}
            location={location}
            loggedIn={loggedIn}
            selectI18nStringById={selectI18nStringById}
            {...actions}
            {...props}
        />
    );
};

export default DefaultToolbarContainer;
