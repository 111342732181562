import fatIconButtonFactory from "../utils/fatIconButtonFactory";
import { MoreMenuVertical } from "@churchofjesuschrist/eden-icons";
import { useSelectI18nStringById } from "../../../../util/custom-hooks";

const MoreMenuButton = fatIconButtonFactory(MoreMenuVertical);

export const Closed = ({ changeView }) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <MoreMenuButton
            onClick={() => changeView("DefaultToolbar")}
            title={selectI18nStringById("annotationMenuOpenToolbarText")}
            data-testid="open-fat"
        />
    );
};

export default Closed;
