import {
    memo,
    useContext,
    useEffect,
    useMemo,
    useState,
    useRef,
    useReducer,
} from "react";
import styles from "./CrossLinkOverlay.css";
import classes from "classnames";
import { get } from "@churchofjesuschrist/universal-env";
import { createMemoryHistory } from "history";
import { useSelector, useDispatch } from "react-redux";
import { iconButtonFactory } from "../IconButton";
import {
    removeRef,
    addRef,
    updateRef,
    sortPidsByDocumentOrder,
    addHighlight,
    changeActiveAnnotation,
} from "../../actions/annotations";
import { navigate, shouldPreventDefault } from "../../components/SmartLink";
import {
    selectActiveAnnotationId,
    selectActiveAnnotationRefs,
    selectContentTitlePid,
    selectI18nStringById as selectI18nStringByIdBuilder,
    selectPids,
} from "../../selectors";
import { Router, useLocation } from "react-router-dom";
import Lock from "@churchofjesuschrist/eden-body-lock";
import { ChevronLeftLarge } from "@churchofjesuschrist/eden-icons";
import GeneralContext, { GeneralContextProvider } from "../GeneralContext";
import ModalControls from "../ModalControls";
import { ParagraphSelectorProvider } from "../SelectableParagraph";
import Tags from "../Tags";
import { ModalNoHeader } from "../../theming/components/eden-platform-modal";
import Dynamic from "../../containers/dynamic.js";

const { APP_PATHNAME } = get();

const ChevronLeftLargeIconButton = iconButtonFactory(ChevronLeftLarge);

ChevronLeftLargeIconButton.displayName = "ChevronLeftLargeIconButton";

function reducer(state, { payload, type }) {
    let setCopy = new Set(state.values());

    switch (type) {
        case "add":
            return setCopy.add(payload);
        case "remove":
            setCopy.delete(payload);

            return setCopy;
        case "replace":
            payload = payload || [];

            return new Set(payload);
        default:
            throw new Error("Action type unknown");
    }
}

const CrossLinkOverlay = (props) => {
    const { lang } = useContext(GeneralContext);
    const location = useLocation();
    const history = useMemo(
        () =>
            createMemoryHistory({
                initialEntries: [`${APP_PATHNAME}/lib?lang=${lang}`],
            }),
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const selectI18nStringById = useSelector(selectI18nStringByIdBuilder);
    const headerTitleMap = {
        createLink: selectI18nStringById("createCrossLinkHeading"),
        editLink: selectI18nStringById("editCrossLinkHeading"),
    };
    const activeAnnotationId = useSelector(selectActiveAnnotationId);
    const crossLinks = useSelector(selectActiveAnnotationRefs) || [];
    const contentTitlePid = useSelector((state) =>
        selectContentTitlePid(state, history.location)
    );
    const allPids = useSelector((state) => selectPids(state, history.location));
    const reduxDispatch = useDispatch();
    const overlayRefEl = useRef(null);
    const [activeCrossLink, setActiveCrossLink] = useState("");
    const [saveState, setSaveState] = useState("save");
    const [selectedPids, dispatch] = useReducer(reducer, new Set());
    const contextValue = useMemo(
        () => ({ selectedPids, dispatch }),
        [selectedPids, dispatch]
    );
    const headerTitle = activeCrossLink
        ? headerTitleMap.editLink
        : headerTitleMap.createLink;

    useEffect(() => {
        overlayRefEl.current.focus();
        let returnFocusEl = document.getElementById("content");

        return () => {
            returnFocusEl.focus();
        };
    }, []);

    useEffect(() => {
        if (saveState !== "save") {
            setSaveState("save");
        }
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCrossLink, selectedPids]);

    useEffect(() => {
        // history.listen() returns it's own clean up function:
        // https://github.com/ReactTraining/history/blob/master/docs/GettingStarted.md#cleaning-up
        return history.listen((location, action) => {
            if (!location.state?.isCrossLink && action !== "REPLACE") {
                // clear state if navigating
                setActiveCrossLink("");
                dispatch({ type: "replace", payload: [] });
            }
        });
    }, [history, selectedPids]);

    const saveHandlerFactory = (thunk) => async (value) => {
        setSaveState("saving");

        try {
            await thunk(value);
            setSaveState("saved");
        } catch (err) {
            setSaveState("error");
        }
    };

    const handleSave = saveHandlerFactory(async (saveAll) => {
        if (activeAnnotationId === null) {
            const { offsets } = props;
            const createAnnotation = async () => {
                const addHighlightAnnotation = await reduxDispatch(
                    addHighlight({ offsets }, location)
                );

                return addHighlightAnnotation;
            };

            reduxDispatch(changeActiveAnnotation(await createAnnotation()));
        }

        if (
            activeCrossLink ||
            selectedPids.size > 0 ||
            (saveAll && contentTitlePid)
        ) {
            const pids =
                selectedPids.size > 0
                    ? selectedPids
                    : new Set([contentTitlePid]);

            if (activeCrossLink) {
                // Was there a change to the paragraphs selected?
                const pidsString = [...pids].toString(",");

                if (pidsString !== activeCrossLink) {
                    reduxDispatch(
                        updateRef(pids, activeCrossLink, history.location)
                    );
                }
            } else {
                reduxDispatch(addRef(pids, history.location));
            }

            setActiveCrossLink(
                sortPidsByDocumentOrder(pids, allPids).toString(",")
            );
        }
    });

    const handleCloseWithoutSave = () => {
        dispatch({ type: "replace", payload: [] });
        setActiveCrossLink("");
        props.onClose();
    };

    const handleSaveAndClose = () => {
        handleSave();
        setActiveCrossLink("");
        props.onClose();
    };

    const handleTagClick = (e, i, href) => {
        const crossLinkClicked = crossLinks[i].pid;

        if (activeCrossLink === crossLinkClicked) {
            // Unset if same tag is clicked
            setActiveCrossLink("");
            dispatch({ type: "replace", payload: [] });
        } else {
            setActiveCrossLink(crossLinkClicked);
            dispatch({ type: "replace", payload: crossLinkClicked.split(",") });

            if (shouldPreventDefault(e)) {
                e.preventDefault();
                navigate(href, lang, history, {
                    isCrossLink: crossLinkClicked,
                });
            }
        }
    };

    const handleRemoveRef = (e, i) => {
        // Is removed ref the activeCrossLink?
        if (activeCrossLink === crossLinks[i].pid) {
            // clear selectedPids
            dispatch({ type: "replace", payload: [] });
        }

        setActiveCrossLink("");
        reduxDispatch(removeRef(crossLinks[i].pid));
    };

    const disableSave = !(selectedPids.size > 0 || contentTitlePid);

    return (
        <ModalNoHeader
            open
            className={styles.overlay}
            onClose={handleSaveAndClose}
        >
            <GeneralContextProvider lang={lang} crossLinkMode={true}>
                <ParagraphSelectorProvider value={contextValue}>
                    <Router history={history}>
                        <div
                            className={styles.overlayBody}
                            tabIndex="-1"
                            ref={overlayRefEl}
                            aria-live="polite"
                            role="dialog"
                            id="cross-link-overlay"
                        >
                            <Lock active={true} />
                            <header
                                className={classes(
                                    styles.header,
                                    styles.hasBorder
                                )}
                            >
                                <ChevronLeftLargeIconButton
                                    className={styles.backButton}
                                    onClick={history.goBack}
                                    data-testid="cross-link-back"
                                />
                                <span
                                    className={styles.overlayTitle}
                                    data-testid="cross-link-title"
                                >
                                    {headerTitle}
                                </span>
                                <ModalControls
                                    className={styles.modalControls}
                                    close={handleCloseWithoutSave}
                                    disableSave={disableSave}
                                    saveAll={() => handleSave(true)}
                                    saveState={saveState}
                                    selectI18nStringById={selectI18nStringById}
                                />
                            </header>
                            <Tags
                                className={styles.crossLinks}
                                handleClick={handleTagClick}
                                onClose={handleRemoveRef}
                                editing={activeCrossLink}
                                tags={crossLinks}
                                type="combo"
                            />
                            <div className={styles.overlayContent}>
                                <Dynamic crossLinkMode={true} />
                            </div>
                        </div>
                    </Router>
                </ParagraphSelectorProvider>
            </GeneralContextProvider>
        </ModalNoHeader>
    );
};

export default memo(CrossLinkOverlay);
